import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

/**
 * Scroll the horizontal sections with animations.
 */
function horizontalScroll() {
    const sections = gsap.utils.toArray("[data-horizontal-scroll]");

    sections.forEach((instance) => {
        const startValue = instance.dataset.horizontalScroll || "top 20%";
        let scrollArea = instance;

        const tween = gsap.to(instance, {
            x: () => getScrollAmount(instance, scrollArea),
            duration: 3,
            ease: "none"
        });

        ScrollTrigger.create({
            trigger: instance,
            start: startValue,
            end: () => `+=${getScrollAmount(instance, scrollArea) * -1}`,
            pin: instance,
            animation: tween,
            scrub: 1,
            invalidateOnRefresh: true,
            markers: false,
            pinnedContainer: instance
        });
    });
}

/**
 * Calculate the scroll amount for the given moveElement within the pinnedContainer.
 *
 * @param {HTMLElement} moveElement - The element to be scrolled horizontally.
 * @param {HTMLElement} pinnedContainer - The container element to which moveElement is pinned.
 * @returns {number} - The horizontal scroll amount.
 */
function getScrollAmount(moveElement, pinnedContainer) {
    let containerWidth =
        pinnedContainer.getBoundingClientRect().width -
        getCombinedPadding(pinnedContainer) -
        getCombinedPadding(moveElement.parentElement);
    let moveEleWidth = moveElement.scrollWidth;
    return -(moveEleWidth - containerWidth);
}

/**
 * Get the combined horizontal padding of an element.
 *
 * @param {HTMLElement} element - The element to calculate padding for.
 * @returns {number} - The combined horizontal padding.
 */
function getCombinedPadding(element) {
    const styles = window.getComputedStyle(element);
    const combinedPadding =
        parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
    return combinedPadding;
}

document.addEventListener("DOMContentLoaded", horizontalScroll);